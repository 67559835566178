body {
  margin: 0;
  font-family: 'karla-regular', 'Open Sans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-stretch: normal;
}

* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
