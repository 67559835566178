.slide-enter {
  transform: translateY(100%);
  z-index: 251 !important;
}

.slide-enter.slide-enter-active {
  transform: translateY(0);
  transition: transform 400ms cubic-bezier(0, 0, 0.4, 1);
  transition-delay: 80ms;
  z-index: 251 !important;
}

.slide-enter-done {
  z-index: 251 !important;
}

.slide-exit {
  transform: translateY(0);
  z-index: 251 !important;
}
.slide-exit-active {
  transform: translateY(100%);
  transition: transform 400ms cubic-bezier(0.4, 0.15, 1, 1);
  transition-delay: 400ms;
  z-index: 251 !important;
}

.appear-enter {
  opacity: 0;
  transform: translateY(10px);
}

.appear-enter-active {
  opacity: 1;
  transform: none;
  transition: transform 300ms cubic-bezier(0.05, 0.7, 0.1, 1.2),
    opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.slide-in-sim-enter {
  transform: translateY(100%);
  z-index: 251 !important;
}

.slide-in-sim-enter-active {
  transform: translateY(0);
  transition: transform 400ms cubic-bezier(0, 0, 0.4, 1);
  transition-delay: 100ms;
  z-index: 251 !important;
}

.slide-in-sim-enter-done {
  z-index: 251 !important;
}

.slide-in-sim-exit {
  transition-delay: 500ms;
}

.slide-in-sim-exit-active {
  transition-delay: 500ms;
}

.slide-out-sim-enter {
  transition-delay: 500ms;
}

.slide-out-sim-enter-active {
  transition-delay: 500ms;
}

.slide-out-sim-exit {
  transform: translateY(0);
  z-index: 251 !important;
}

.slide-out-sim-exit-active {
  transform: translateY(100%);
  transition: transform 400ms cubic-bezier(0.4, 0.15, 1, 1);
  transition-delay: 0ms;
  z-index: 251 !important;
}

.slide-out-sim-exit-done {
  z-index: 251 !important;
}
